import { KeycloakAuthService } from 'src/app/services/keycloak-auth.service';

export const environment = {
    production: false,
    environment: 'DEV',
    apiKey: 'AIzaSyCU-RFgD67SOM02pShLuyk1vXSpnmdcC20',
    dynatraceKey: '',
    lienHermesMesComm: 'https://rct-interactive-tessi-veolia.tessitechno.fr/interactive/#mes-communications-a-traiter',
    lienCatalogueService: 'https://veglobal.service-now.com/water?id=sc_cat_item&sys_id=164a99951bab2c5c83faa868b04bcb83',
    lienCourrierEnMasse: 'https://staging-veolia.ezdoc.fr/bulk_mails',
    lienModelExtraction: 'https://docs.google.com/spreadsheets/d/1XSZutTzRj_wnyXOas8fmmg1Acyl1F6ACpvRZUVll1tw/edit#gid=0',
    lienGuideUtilisateur: 'https://docs.google.com/presentation/d/16lGK4h7D0AwE8mgneyElc70BXY03zsNB7iivWanDW3A/edit#slide=id.g1122694abe2_0_1',
    lienModeleCommunications: 'https://drive.google.com/drive/u/0/folders/1AwH8oDleXJjjvmTH0i-tUo8xAvz62OzU',
    publicChapterEmail: 'fr.water.ist.conso-srvc-editique.run-editique.all.groups@veolia.com',
    apiURL: "https://mfq1x2yt4k.execute-api.eu-west-1.amazonaws.com/dev",
    s3BucketFlux: 'veolia-osiris-echange-dev',
    awsRegion: "eu-west-1",
    googleClientId: "290638486407-eoff3e5psd3v41mk7ofg6iebndvu8nue.apps.googleusercontent.com",
    identityPoolId: "eu-west-1:a8aae4a6-4846-4b28-864d-f9dbe4c27b18",
    build: "104",
    commit: "be23290396137a85d52e458de4ffc8d05cb558d9",

    auth: {
      config: {
        config: {
          url: 'https://keycloak-hom.dir.istefr.fr/',
          realm: 'vef',
          clientId: 'MERCURE',
        },
        initOptions: {
          onLoad: 'check-sso',
          flow: 'standard',
          'enable-cors': true,
          pkceMethod: 'S256',
          silentCheckSsoRedirectUri:
            'https://dev-mercure.dir.istefr.fr/assets/silent-check-sso.html',
          'public-client': true,
        },
      },
      loginRedirectUri: 'https://dev-mercure.dir.istefr.fr/configuration',
      logoutRedirectUri: 'https://dev-mercure.dir.istefr.fr/home',
      provider: KeycloakAuthService,
      federatedSignInProvider: 'keycloak-hom.dir.istefr.fr/realms/vef',
    },
};
